<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">{{ $t("REPORT_SALES.INVOICES_RECEIPTS") }}</p>
    <div class="filter-part weight-700">
      <h5 class="weight-700 m-b-10">{{ $t("REPORT_SALES.TIME_FILTERING") }}</h5>
      <div class="d-flex align-items-center flex-wrap">
        <div>
          <b-row class="m-b-10">
            <b-col lg="5" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.START_DATE") }}
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <Datepicker 
                v-model="filter.start_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </b-col>
          </b-row>
          <b-row class="m-b-10">
            <b-col lg="5" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.END_DATE") }} :
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <Datepicker 
                v-model="filter.end_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </b-col>
          </b-row>
        </div>
        <div class="time-part">
          <b-row class="m-b-10">
            <b-col lg="4" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.START_HOUR") }} :
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="filter.start_hours.options" 
                class="input cus-select time-select mx5"
                v-model="filter.start_hours.selected"
              >
              </vue-select> 
              <span>:</span> 
              <vue-select 
                :options="filter.start_mins.options" 
                class="input cus-select time-select mx5"
                v-model="filter.start_mins.selected"
              >
              </vue-select>
            </b-col>
          </b-row>
          <b-row class="m-b-10">
            <b-col lg="4" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.END_HOUR") }} :
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="filter.end_hours.options" 
                class="input cus-select time-select mx5"
                v-model="filter.end_hours.selected"
              >
              </vue-select> : 
              <vue-select 
                :options="filter.end_mins.options" 
                class="input cus-select time-select mx5"
                v-model="filter.end_mins.selected"
              >
              </vue-select>
            </b-col>
          </b-row>
        </div>
        <div class="range-part">
          <div class="m-b-10 d-flex">
            <label for="" class="space-nowrap">{{ $t("REPORT_SALES.RANGE_OF_DATES") }}</label>
            <vue-select 
              :options="filter.dateRange.options" 
              class="input cus-select payment-form_select no-uppercase-select mx5"
              v-model="filter.dateRange.selected"
              @input="changeDateRange"
            >
            </vue-select> 
          </div>
          <div class="m-b-10 d-flex align-items-center">
            <v-checkbox v-model="filter.isTimeLimit" color="#0D3856" class="cus-checkbox pt5 mx5 inline-checkbox"></v-checkbox>
            <label for="" class="m-0">{{ $t("REPORT_SALES.WITHOUT_TIME_LIMITS") }}</label>
          </div>
        </div>
      </div>
    </div>
    <button class="primary-btn search_btn" @click="filterReport(1)">{{ $t("REPORT_SALES.SEARCH") }}</button>

    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t("COMMON.SEARCH") }}</p>
          <v-text-field v-model="search" single-line hide-details class="search-form"></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="reportData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
      >
        <template v-slot:[`item.viewing`]="{ }">
          <img src="/assets/img/icons/desktop-light.svg" alt="" class="table-icon pointer">
        </template>
        <template v-slot:[`item.download`]="{ }">
          <div class="download-icon pointer">
            <v-icon medium color="#0092CF">arrow_downward</v-icon>
          </div>
        </template>
      </v-data-table>

    </div>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import "bootstrap";
import "../../common/plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "ReceiptsInvoices",
  components: {
    Loading
  },  
  computed: {
    ...mapState({
      loading: state => state.report.loading,
      // reportData: state => state.report.salesPaymentReportData_receipts,
    }),
    currentLang: function() {
      let currentLang = localStorage.getItem("language");
      currentLang = currentLang ? currentLang.toLowerCase() : 'he';
      return currentLang;
    }
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("REGISTER_RESELLER.BUSINESS_NAME"),
          align: "center",
          sortable: false,
          value: "business_name"
        },
        {
          text: this.$t("REGISTER_RESELLER.DOCUMENT_TYPE"),
          align: "center",
          sortable: false,
          value: "document_type"
        },
        {
          text: this.$t("REPORT_SALES.RECEIPT_TAX_INVOICE"),
          align: "center",
          sortable: false,
          value: "invoice"
        },
        {
          text: this.$t("COMMON.DATE"),
          align: "center",
          sortable: false,
          value: "date"
        },
        {
          text: this.$t("COMMON.HOUR"),
          align: "center",
          sortable: false,
          value: "time"
        },
        {
          text: this.$t("RESELLER_DETAILS.SUM"),
          align: "center",
          sortable: false,
          value: "sum"
        },
        {
          text: this.$t("RESELLER_DETAILS.PAYMENT_METHODS"),
          align: "center",
          sortable: false,
          value: "payment_methods"
        },
        {
          text: this.$t("REGISTER_RESELLER.REMARKS"),
          align: "center",
          sortable: false,
          value: "remarks"
        },
        {
          text: this.$t("REPORT_SALES.VIEWING"),
          align: "center",
          sortable: false,
          value: "viewing"
        },
        {
          text: this.$t("REPORT_SALES.DOWNLOAD"),
          align: "center",
          sortable: false,
          value: "download"
        }
      ],
      filter: {
        isTimeLimit: false,
        dateRange: {
          selected: {label: "None", code: 0},
          options: [
            {label: "Current month", code: 1}, 
            {label: "Last month", code: 2} 
          ]
        },
        start_date: "",
        end_date: "",
        start_hours: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
          selected: "00"
        },
        start_mins: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
          selected: "00"
        },
        end_hours: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
          selected: "00"
        },
        end_mins: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
          selected: "00"
        },

      },
      
      format: "d MMMM yyyy",
      expanded: [],
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      filtersInUse: {},
      reportData: [
        {
          business_name: "הפיצוציה",
          document_type: "קבלה",
          invoice: "123",
          date: "18/01/2019",
          time: "05:48:03",
          sum: "752",
          payment_methods: "הוראת קבע",
          remarks: "בלה בלה בלה",
          viewing: "",
          download: ""
        }
      ]
    };
  },
  created() {
    this.filter.end_hours.selected = "23";
    this.filter.end_mins.selected = "59";
  },
  methods: {
    ...mapActions("report", {
      filter_sales_payment_report: "filter_sales_payment_report",
      filter_sales_payment_report_print: "filter_sales_payment_report_print",
      export_sales_report: "export_sales_report"
    }),

    changeDateRange() {
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (this.filter.dateRange.selected.code == 1) {
        this.filter.start_date = new Date(year, month, 1);
        this.filter.end_date = new Date(year, month + 1, 0);
        this.filter.start_hours.selected = "00"
        this.filter.start_mins.selected = "00"
        this.filter.end_hours.selected = "23"
        this.filter.end_mins.selected = "59"
      } else if (this.filter.dateRange.selected.code == 2) {
        if (month == 0) {
          year = year - 1;
          month = 12;
        }
        this.filter.start_date = new Date(year, month - 1, 1);
        this.filter.end_date = new Date(year, month, 0);
        this.filter.start_hours.selected = "00";
        this.filter.start_mins.selected = "00";
        this.filter.end_hours.selected = "23";
        this.filter.end_mins.selected = "59";
      }
    },

    prepareFilters() {
      let end_date = moment().format("YYYY-MM-DD");
      if (this.filter.end_date != "") {
        end_date = moment(this.filter.end_date).format("YYYY-MM-DD");
      }
      const filters = {
        startDate: moment(this.filter.start_date).format("YYYY-MM-DD"),
        startDateTime: this.filter.start_hours.selected + ":" + this.filter.start_mins.selected,
        endDate: end_date,
        endDateTime: this.filter.end_hours.selected + ":" + this.filter.end_mins.selected
      }

      return filters;
    },
    filterReport(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      let skip = (page - 1) * itemsPerPage;
      if (page === 1) {
        this.filtersInUse = this.prepareFilters()
      }
      const filters = this.filtersInUse;
      this.filter_sales_payment_report({limit: itemsPerPage, skip: skip, filters: filters});
    }
  },
  watch: {
      options: {
          handler () {
              this.filterReport();
          },
          deep: true,
      },
  },
  mounted() {
    this.filter.start_date = new Date();
  }
};
</script>

<style lang="scss">
.time-select{
  width: 70px;
  .vs__selected {
    height: 100%;
    top: 0;
  }
  .vs__dropdown-menu {
    min-width: 70px;
    max-width: 70px;
  }
  .vs__search {
    padding: 0;
  }
}
.payment-form_select {
  min-width: 170px;
}
.cus-select {
  ul {
    padding-left: 0;
  }
} 
.inline-checkbox .v-input__control {
  height: 100%;
}
</style>
<style scoped lang="scss">
.table-icon {
  width: 30px;
}
.download-icon {
  width: 26px;
  height: 28px;
  border: 2px solid #0092CF;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  .v-icon.v-icon {
    font-size: 20px;
  }
}

.filter-part {
  border: 1px solid #707070;
  margin: 20px 50px 0 50px;
  padding: 10px 15px 30px;
  .input {
    height: 30px;
    padding: 0 10px;
    border-radius: 8px;
  }
  .calendar-img {
    width: 25px;
    position: absolute;
    right: 12px;
    left: unset;
    top: unset;
  }
  .v-input__control {
    height: 100%;
  }
  [class*="col-"] {
    padding: 0 !important;
  }
  .row {
    margin: 0;
  }
  .time-part {
    margin-left: 50px;
    margin-right: 0;
    min-width: 300px;
    .vue-select {
      padding: 0 4px;
    }
  }
  .range-part {
    margin-right: 50px;
  }
  .filter-search_icon {
    position: absolute;
    left: 14px;
    width: 20px;
    top: 5px;
  }
  .filter-item {
    margin-left: 0;
    margin-right: 20px;
    
  }
}

.total-value {
  font-weight: 600;
  color: #707070;
  font-size: 22px;
  span {
    font-size: 18px;
    color: #002036;
  }
}
.main-table {
  .ticket-item {
    border: 1px solid #707070;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    margin: 0 auto;
    text-align: center;
  }
  .print-img {
    width: 40px;
  }
}
.label-align {
  justify-content: flex-end;
}
.search_btn {
  margin: 0 auto;
  margin-top: 20px;
}
.action-part {
  margin: 20px 70px;
  .print-img {
    width: 50px;
  }
  .excel-img {
    width: 60px;
    margin-left: 50px;
    margin-right: 15px;
  }
  label {
    font-weight: 700;
    margin: 0;
  }
  >div {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
}
.rtl-type {
  label-align {
    justify-content: flex-start;
  }
  .excel-img {
    margin-right: 50px;
    margin-left: 15px;
  }
  .filter-part {
    .filter-item {
      margin-left: 20px;
      margin-right: 0;
    }
    .time-part {
      margin-right: 50px;
      margin-left: 0;
    }
    .calendar-img {
      left: 12px;
      right: unset;
    }
  }
  .time-select .vs__actions {
    margin-right: -10px;
  }
}
@media screen and (max-width: 1548px){
  .filter-part {
    .range-part {
      margin-right: 0;
      margin-left: 0;
    }
  } 
}
@media screen and (max-width: 768px){
  .filter-part {
    .time-part {
      margin-right: 0;
      margin-left: 0;
    }
  } 
}
@media screen and (max-width: 480px){
  .filter-part {
    margin: 20px 20px 0;
    .filter-item {
      width: 100%;
      flex-wrap: wrap;
      margin: 10px 0 0 !important;
      label {
        width: 100%;
      }
      .input {
        width: 100%;
        margin: 0 !important;
      }
    }
  }
  .search_btn {
    max-width: 80%;
  }
}
@media screen and (min-width: 992px){
  .filter-width {
    max-width: 170px;
  }
}
@media screen and (max-width: 992px){
  .label-align {
    justify-content: flex-start;
    padding-bottom: 0 !important;
  }
  .rtl-type .label-align {
    justify-content: flex-end;
    padding-bottom: 0 !important;
  }
}

</style>